@import "@/assets/scss/style.scss";

.wrapper {
    display: flex;
}

.item {
    margin-right: 16px;
    display: table;
    table-layout: fixed;

    &:last-child {
        margin-right: 0;
    }
}
