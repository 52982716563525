@import "@/assets/scss/style.scss";

.userAliasItemWrapper {
    padding: 4px 8px;
    border-bottom: 1px solid $grey-4;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    &:last-child {
        border-bottom: none;
    }

    .countryName {
        margin-bottom: 8px;
    }

    .actions {
        & > * {
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

