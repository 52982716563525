@import "@/assets/scss/style.scss";

.c-sales-process-form {
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 24px;
    border: 1px solid $grey-1;

    &:last-child {
        margin-bottom: 0;
    }

    &__label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__item {
        margin-bottom: 12px;
    }

    &__delete {
        color: $dull-red;

        &:hover {
            color: $watermelon;
        }
    }
}
