@import "@/assets/scss/style.scss";

.c-category-selector {
    &__radio-wrapper {
        /*text-align: center;*/
    }

    &__products-wrapper {
        padding: 25px 0 20px;
    }
}
