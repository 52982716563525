@import "@/assets/scss/style.scss";

.ant-radio-group {
    display: flex;

    &.is-grid {
        display: block;
        margin: 0 auto;

        .All {
            display: flex;
            border-radius: 4px;
            margin-bottom: 1px;
        }

        .area-1 {
            border-radius: 4px 0 0 4px;

            &:before {
                display: none;
            }
        }
    }

    .ant-radio-button-wrapper {
        border: 1px solid $very-light-blue;
        width: var(--button-width);
        text-align: center;
        color: $light-periwinkle;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            border: 1px solid $light-periwinkle;
            color: $mid-blue;
        }

        &-checked {
            border: 1px solid $mid-blue;
            color: $mid-blue;
            outline: none;
            box-shadow: none;

            &:hover {
                border: 1px solid $mid-blue;
                box-shadow: none;
                outline: none;
            }
        }
    }

    &.ant-radio-group-solid {
        .ant-radio-button-wrapper {
            color: $mid-blue;
            border: 1px solid $mid-blue;

            &:hover {
                background: $light-periwinkle;
                color: $mid-blue;
            }

            &-checked {
                background: $mid-blue;
                color: $white-two;
                outline: none;

                &:hover {
                    border-color: $mid-blue;
                    box-shadow: none;
                    outline: none;
                    background: $mid-blue;
                    color: $white-two;
                }
            }
        }
    }
}
