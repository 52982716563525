@import "@/assets/scss/style.scss";

.c-cms-index {
    &__delete-button {
        width: auto;
        display: block;
        margin: 5px auto 0;
    }
    &__product-picker {
        margin: 24px auto 0;
        border-radius: 10px;
        padding: 24px;
        width: 80%;
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
    }
    &__locale-picker {
        width: 80%;
    }
}
