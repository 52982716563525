@import "@/assets/scss/style.scss";

.wrapper {
    display: flex;
}


.item {
    width: 100%;
    margin-right: 16px;

    &:last-child {
        margin-right: 0;
    }
}

.textarea {
    min-height: 100px;
}

