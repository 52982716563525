@import "@/assets/scss/style.scss";

.c-product-selector {
    display: flex;
    flex-direction: column;

    &__product-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__product-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__product-group-title {
        background-color: $pale-grey;
        padding: 5px;
        margin-bottom: 10px;
    }

    &__product {
        display: block;
        margin: 0 15px 16px 0;
        padding: 10px 12px;
        border-radius: 5px;
        cursor: pointer;
        max-width: 100px;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: $grey-three;
        }

        &.is-selected {
            background-color: $very-light-blue;
        }
    }

    &__image {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 10px;
        max-width: 64px;
    }

    &__product-title {
        margin-top: 6px;
        text-align: center;
        word-break: break-word;
    }

    &__product-popover-button {
        display: block;
        width: 100%;
    }

    &__product-system-icon {
        width: 64px;
        height: 64px;
        background-color: $grey-4;
        border-radius: 5px;
        margin-bottom: 6px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        image-rendering: -webkit-optimize-contrast;
    }

    &__product-system {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 12px;
        cursor: pointer;
        padding: 12px;
        transition: background-color 0.2s ease-in-out;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &:hover {
            background-color: $grey-three;
        }

        &.is-selected {
            border-bottom: 3px solid $roltek-blue;
            background-color: $very-light-blue;
        }


        &:last-child {
            margin-right: 0;
        }
    }

    &__product-systems {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid $grey-4;
        padding-top: 20px;
    }
}
